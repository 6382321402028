import { default as about_45usWjawxlkv4gMeta } from "/app/pages/about-us.vue?macro=true";
import { default as index5pIReWxFzkMeta } from "/app/pages/alternative/index.vue?macro=true";
import { default as zapscale_45vs_45catalystchLr8jZeHBMeta } from "/app/pages/alternative/zapscale-vs-catalyst.vue?macro=true";
import { default as zapscale_45vs_45churnzeroq9JcCB0lBHMeta } from "/app/pages/alternative/zapscale-vs-churnzero.vue?macro=true";
import { default as zapscale_45vs_45clientsuccessN9RFjnxFjbMeta } from "/app/pages/alternative/zapscale-vs-clientsuccess.vue?macro=true";
import { default as zapscale_45vs_45custify45qbg9UdxSMeta } from "/app/pages/alternative/zapscale-vs-custify.vue?macro=true";
import { default as zapscale_45vs_45gainsightyXmYenSZV3Meta } from "/app/pages/alternative/zapscale-vs-gainsight.vue?macro=true";
import { default as zapscale_45vs_45planhatpYNimxl3a9Meta } from "/app/pages/alternative/zapscale-vs-planhat.vue?macro=true";
import { default as zapscale_45vs_45totangovU6BfyGCFSMeta } from "/app/pages/alternative/zapscale-vs-totango.vue?macro=true";
import { default as zapscale_45vs_45vitallyGz3ikXzZmwMeta } from "/app/pages/alternative/zapscale-vs-vitally.vue?macro=true";
import { default as black_45friday_45offerI8Km3N0qdmMeta } from "/app/pages/black-friday-offer.vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as careersb3LI3USwU5Meta } from "/app/pages/careers.vue?macro=true";
import { default as tagboxrAVZEapllSMeta } from "/app/pages/case-study/tagbox.vue?macro=true";
import { default as compliancegjle6cISeRMeta } from "/app/pages/compliance.vue?macro=true";
import { default as contact_45uszp1HYnA7MsMeta } from "/app/pages/contact-us.vue?macro=true";
import { default as cookie_45policy2bZRk7g5I8Meta } from "/app/pages/cookie-policy.vue?macro=true";
import { default as _91slug_933NBBol8J1wMeta } from "/app/pages/customer-success-glossary/[slug].vue?macro=true";
import { default as indexATYTC1vK6NMeta } from "/app/pages/customer-success-glossary/index.vue?macro=true";
import { default as customers8i6pIOdJqKMeta } from "/app/pages/customers.vue?macro=true";
import { default as demoNzyx2jXZ3QMeta } from "/app/pages/demo.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as featuresjehmaWJ0GzMeta } from "/app/pages/features.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as intercombTWjeQc5cAMeta } from "/app/pages/integrations/communication/intercom.vue?macro=true";
import { default as hubspotKHaSvwO3lmMeta } from "/app/pages/integrations/crm/hubspot.vue?macro=true";
import { default as pipedrivepO9aW72ETOMeta } from "/app/pages/integrations/crm/pipedrive.vue?macro=true";
import { default as salesforceJRyK9n0vgzMeta } from "/app/pages/integrations/crm/salesforce.vue?macro=true";
import { default as zohoshJ51jtiiYMeta } from "/app/pages/integrations/crm/zoho.vue?macro=true";
import { default as indexwfmqj0GVbMMeta } from "/app/pages/integrations/index.vue?macro=true";
import { default as amplitudemR3QxO0Ge9Meta } from "/app/pages/integrations/product-analytics/amplitude.vue?macro=true";
import { default as mixpanelvMcFgoMCJGMeta } from "/app/pages/integrations/product-analytics/mixpanel.vue?macro=true";
import { default as chargebeeEWSq4NvvoMMeta } from "/app/pages/integrations/subscriptions/chargebee.vue?macro=true";
import { default as stripeM01Y0PtcXBMeta } from "/app/pages/integrations/subscriptions/stripe.vue?macro=true";
import { default as zoho_45books1XGUedZQDRMeta } from "/app/pages/integrations/subscriptions/zoho-books.vue?macro=true";
import { default as zoho_45subscriptionsBzYTTEDlvGMeta } from "/app/pages/integrations/subscriptions/zoho-subscriptions.vue?macro=true";
import { default as hubspot_45service_45hubaTSuA5aJ86Meta } from "/app/pages/integrations/ticketing/hubspot-service-hub.vue?macro=true";
import { default as intercomA6tXgIajDzMeta } from "/app/pages/integrations/ticketing/intercom.vue?macro=true";
import { default as jiraBN0dLMzSBXMeta } from "/app/pages/integrations/ticketing/jira.vue?macro=true";
import { default as zendesk76yx0rCdzwMeta } from "/app/pages/integrations/ticketing/zendesk.vue?macro=true";
import { default as batonll0yaI0XQVMeta } from "/app/pages/partnership/baton.vue?macro=true";
import { default as saasinsider2Ma9HmxEugMeta } from "/app/pages/partnership/saasinsider.vue?macro=true";
import { default as _91slug_93otL1BKh9yuMeta } from "/app/pages/podcasts/[slug].vue?macro=true";
import { default as indexEHDN5ovAzVMeta } from "/app/pages/podcasts/index.vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as search_45resultsL4dkzeKwmzMeta } from "/app/pages/search-results.vue?macro=true";
import { default as sitemapbFBPe0edUzMeta } from "/app/pages/sitemap.vue?macro=true";
import { default as customer_45churneSL15zKpSHMeta } from "/app/pages/solutions/customer-churn.vue?macro=true";
import { default as customer_45success_45headsEsztkf68EaMeta } from "/app/pages/solutions/customer-success-heads.vue?macro=true";
import { default as customer_45success_45managersDpMaI2iHwEMeta } from "/app/pages/solutions/customer-success-managers.vue?macro=true";
import { default as customer_45visibilityByo0VYvZgQMeta } from "/app/pages/solutions/customer-visibility.vue?macro=true";
import { default as founders_45and_45cxosmAro6VpWi0Meta } from "/app/pages/solutions/founders-and-cxos.vue?macro=true";
import { default as product_45adoptionz2g9wDBa8WMeta } from "/app/pages/solutions/product-adoption.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
import { default as thank_45youwub3K0ZhI4Meta } from "/app/pages/thank-you.vue?macro=true";
import { default as _91token_935yfqHd94b8Meta } from "/app/pages/verify/[token].vue?macro=true";
import { default as _91slug_93MTErUPid3CMeta } from "/app/pages/webinars/[slug].vue?macro=true";
import { default as indexIbGDnNNySuMeta } from "/app/pages/webinars/index.vue?macro=true";
export default [
  {
    name: about_45usWjawxlkv4gMeta?.name ?? "about-us",
    path: about_45usWjawxlkv4gMeta?.path ?? "/about-us",
    meta: about_45usWjawxlkv4gMeta || {},
    alias: about_45usWjawxlkv4gMeta?.alias || [],
    redirect: about_45usWjawxlkv4gMeta?.redirect || undefined,
    component: () => import("/app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: index5pIReWxFzkMeta?.name ?? "alternative",
    path: index5pIReWxFzkMeta?.path ?? "/alternative",
    meta: index5pIReWxFzkMeta || {},
    alias: index5pIReWxFzkMeta?.alias || [],
    redirect: index5pIReWxFzkMeta?.redirect || undefined,
    component: () => import("/app/pages/alternative/index.vue").then(m => m.default || m)
  },
  {
    name: zapscale_45vs_45catalystchLr8jZeHBMeta?.name ?? "alternative-zapscale-vs-catalyst",
    path: zapscale_45vs_45catalystchLr8jZeHBMeta?.path ?? "/alternative/zapscale-vs-catalyst",
    meta: zapscale_45vs_45catalystchLr8jZeHBMeta || {},
    alias: zapscale_45vs_45catalystchLr8jZeHBMeta?.alias || [],
    redirect: zapscale_45vs_45catalystchLr8jZeHBMeta?.redirect || undefined,
    component: () => import("/app/pages/alternative/zapscale-vs-catalyst.vue").then(m => m.default || m)
  },
  {
    name: zapscale_45vs_45churnzeroq9JcCB0lBHMeta?.name ?? "alternative-zapscale-vs-churnzero",
    path: zapscale_45vs_45churnzeroq9JcCB0lBHMeta?.path ?? "/alternative/zapscale-vs-churnzero",
    meta: zapscale_45vs_45churnzeroq9JcCB0lBHMeta || {},
    alias: zapscale_45vs_45churnzeroq9JcCB0lBHMeta?.alias || [],
    redirect: zapscale_45vs_45churnzeroq9JcCB0lBHMeta?.redirect || undefined,
    component: () => import("/app/pages/alternative/zapscale-vs-churnzero.vue").then(m => m.default || m)
  },
  {
    name: zapscale_45vs_45clientsuccessN9RFjnxFjbMeta?.name ?? "alternative-zapscale-vs-clientsuccess",
    path: zapscale_45vs_45clientsuccessN9RFjnxFjbMeta?.path ?? "/alternative/zapscale-vs-clientsuccess",
    meta: zapscale_45vs_45clientsuccessN9RFjnxFjbMeta || {},
    alias: zapscale_45vs_45clientsuccessN9RFjnxFjbMeta?.alias || [],
    redirect: zapscale_45vs_45clientsuccessN9RFjnxFjbMeta?.redirect || undefined,
    component: () => import("/app/pages/alternative/zapscale-vs-clientsuccess.vue").then(m => m.default || m)
  },
  {
    name: zapscale_45vs_45custify45qbg9UdxSMeta?.name ?? "alternative-zapscale-vs-custify",
    path: zapscale_45vs_45custify45qbg9UdxSMeta?.path ?? "/alternative/zapscale-vs-custify",
    meta: zapscale_45vs_45custify45qbg9UdxSMeta || {},
    alias: zapscale_45vs_45custify45qbg9UdxSMeta?.alias || [],
    redirect: zapscale_45vs_45custify45qbg9UdxSMeta?.redirect || undefined,
    component: () => import("/app/pages/alternative/zapscale-vs-custify.vue").then(m => m.default || m)
  },
  {
    name: zapscale_45vs_45gainsightyXmYenSZV3Meta?.name ?? "alternative-zapscale-vs-gainsight",
    path: zapscale_45vs_45gainsightyXmYenSZV3Meta?.path ?? "/alternative/zapscale-vs-gainsight",
    meta: zapscale_45vs_45gainsightyXmYenSZV3Meta || {},
    alias: zapscale_45vs_45gainsightyXmYenSZV3Meta?.alias || [],
    redirect: zapscale_45vs_45gainsightyXmYenSZV3Meta?.redirect || undefined,
    component: () => import("/app/pages/alternative/zapscale-vs-gainsight.vue").then(m => m.default || m)
  },
  {
    name: zapscale_45vs_45planhatpYNimxl3a9Meta?.name ?? "alternative-zapscale-vs-planhat",
    path: zapscale_45vs_45planhatpYNimxl3a9Meta?.path ?? "/alternative/zapscale-vs-planhat",
    meta: zapscale_45vs_45planhatpYNimxl3a9Meta || {},
    alias: zapscale_45vs_45planhatpYNimxl3a9Meta?.alias || [],
    redirect: zapscale_45vs_45planhatpYNimxl3a9Meta?.redirect || undefined,
    component: () => import("/app/pages/alternative/zapscale-vs-planhat.vue").then(m => m.default || m)
  },
  {
    name: zapscale_45vs_45totangovU6BfyGCFSMeta?.name ?? "alternative-zapscale-vs-totango",
    path: zapscale_45vs_45totangovU6BfyGCFSMeta?.path ?? "/alternative/zapscale-vs-totango",
    meta: zapscale_45vs_45totangovU6BfyGCFSMeta || {},
    alias: zapscale_45vs_45totangovU6BfyGCFSMeta?.alias || [],
    redirect: zapscale_45vs_45totangovU6BfyGCFSMeta?.redirect || undefined,
    component: () => import("/app/pages/alternative/zapscale-vs-totango.vue").then(m => m.default || m)
  },
  {
    name: zapscale_45vs_45vitallyGz3ikXzZmwMeta?.name ?? "alternative-zapscale-vs-vitally",
    path: zapscale_45vs_45vitallyGz3ikXzZmwMeta?.path ?? "/alternative/zapscale-vs-vitally",
    meta: zapscale_45vs_45vitallyGz3ikXzZmwMeta || {},
    alias: zapscale_45vs_45vitallyGz3ikXzZmwMeta?.alias || [],
    redirect: zapscale_45vs_45vitallyGz3ikXzZmwMeta?.redirect || undefined,
    component: () => import("/app/pages/alternative/zapscale-vs-vitally.vue").then(m => m.default || m)
  },
  {
    name: black_45friday_45offerI8Km3N0qdmMeta?.name ?? "black-friday-offer",
    path: black_45friday_45offerI8Km3N0qdmMeta?.path ?? "/black-friday-offer",
    meta: black_45friday_45offerI8Km3N0qdmMeta || {},
    alias: black_45friday_45offerI8Km3N0qdmMeta?.alias || [],
    redirect: black_45friday_45offerI8Km3N0qdmMeta?.redirect || undefined,
    component: () => import("/app/pages/black-friday-offer.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cdf2dv6FbPMeta?.name ?? "blog-slug",
    path: _91slug_93cdf2dv6FbPMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93cdf2dv6FbPMeta || {},
    alias: _91slug_93cdf2dv6FbPMeta?.alias || [],
    redirect: _91slug_93cdf2dv6FbPMeta?.redirect || undefined,
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog",
    path: indexgWaMlevJ7RMeta?.path ?? "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect || undefined,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: careersb3LI3USwU5Meta?.name ?? "careers",
    path: careersb3LI3USwU5Meta?.path ?? "/careers",
    meta: careersb3LI3USwU5Meta || {},
    alias: careersb3LI3USwU5Meta?.alias || [],
    redirect: careersb3LI3USwU5Meta?.redirect || undefined,
    component: () => import("/app/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: tagboxrAVZEapllSMeta?.name ?? "case-study-tagbox",
    path: tagboxrAVZEapllSMeta?.path ?? "/case-study/tagbox",
    meta: tagboxrAVZEapllSMeta || {},
    alias: tagboxrAVZEapllSMeta?.alias || [],
    redirect: tagboxrAVZEapllSMeta?.redirect || undefined,
    component: () => import("/app/pages/case-study/tagbox.vue").then(m => m.default || m)
  },
  {
    name: compliancegjle6cISeRMeta?.name ?? "compliance",
    path: compliancegjle6cISeRMeta?.path ?? "/compliance",
    meta: compliancegjle6cISeRMeta || {},
    alias: compliancegjle6cISeRMeta?.alias || [],
    redirect: compliancegjle6cISeRMeta?.redirect || undefined,
    component: () => import("/app/pages/compliance.vue").then(m => m.default || m)
  },
  {
    name: contact_45uszp1HYnA7MsMeta?.name ?? "contact-us",
    path: contact_45uszp1HYnA7MsMeta?.path ?? "/contact-us",
    meta: contact_45uszp1HYnA7MsMeta || {},
    alias: contact_45uszp1HYnA7MsMeta?.alias || [],
    redirect: contact_45uszp1HYnA7MsMeta?.redirect || undefined,
    component: () => import("/app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect || undefined,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933NBBol8J1wMeta?.name ?? "customer-success-glossary-slug",
    path: _91slug_933NBBol8J1wMeta?.path ?? "/customer-success-glossary/:slug()",
    meta: _91slug_933NBBol8J1wMeta || {},
    alias: _91slug_933NBBol8J1wMeta?.alias || [],
    redirect: _91slug_933NBBol8J1wMeta?.redirect || undefined,
    component: () => import("/app/pages/customer-success-glossary/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexATYTC1vK6NMeta?.name ?? "customer-success-glossary",
    path: indexATYTC1vK6NMeta?.path ?? "/customer-success-glossary",
    meta: indexATYTC1vK6NMeta || {},
    alias: indexATYTC1vK6NMeta?.alias || [],
    redirect: indexATYTC1vK6NMeta?.redirect || undefined,
    component: () => import("/app/pages/customer-success-glossary/index.vue").then(m => m.default || m)
  },
  {
    name: customers8i6pIOdJqKMeta?.name ?? "customers",
    path: customers8i6pIOdJqKMeta?.path ?? "/customers",
    meta: customers8i6pIOdJqKMeta || {},
    alias: customers8i6pIOdJqKMeta?.alias || [],
    redirect: customers8i6pIOdJqKMeta?.redirect || undefined,
    component: () => import("/app/pages/customers.vue").then(m => m.default || m)
  },
  {
    name: demoNzyx2jXZ3QMeta?.name ?? "demo",
    path: demoNzyx2jXZ3QMeta?.path ?? "/demo",
    meta: demoNzyx2jXZ3QMeta || {},
    alias: demoNzyx2jXZ3QMeta?.alias || [],
    redirect: demoNzyx2jXZ3QMeta?.redirect || undefined,
    component: () => import("/app/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: faqIMkmXQpKKaMeta?.name ?? "faq",
    path: faqIMkmXQpKKaMeta?.path ?? "/faq",
    meta: faqIMkmXQpKKaMeta || {},
    alias: faqIMkmXQpKKaMeta?.alias || [],
    redirect: faqIMkmXQpKKaMeta?.redirect || undefined,
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: featuresjehmaWJ0GzMeta?.name ?? "features",
    path: featuresjehmaWJ0GzMeta?.path ?? "/features",
    meta: featuresjehmaWJ0GzMeta || {},
    alias: featuresjehmaWJ0GzMeta?.alias || [],
    redirect: featuresjehmaWJ0GzMeta?.redirect || undefined,
    component: () => import("/app/pages/features.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: intercombTWjeQc5cAMeta?.name ?? "integrations-communication-intercom",
    path: intercombTWjeQc5cAMeta?.path ?? "/integrations/communication/intercom",
    meta: intercombTWjeQc5cAMeta || {},
    alias: intercombTWjeQc5cAMeta?.alias || [],
    redirect: intercombTWjeQc5cAMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/communication/intercom.vue").then(m => m.default || m)
  },
  {
    name: hubspotKHaSvwO3lmMeta?.name ?? "integrations-crm-hubspot",
    path: hubspotKHaSvwO3lmMeta?.path ?? "/integrations/crm/hubspot",
    meta: hubspotKHaSvwO3lmMeta || {},
    alias: hubspotKHaSvwO3lmMeta?.alias || [],
    redirect: hubspotKHaSvwO3lmMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/crm/hubspot.vue").then(m => m.default || m)
  },
  {
    name: pipedrivepO9aW72ETOMeta?.name ?? "integrations-crm-pipedrive",
    path: pipedrivepO9aW72ETOMeta?.path ?? "/integrations/crm/pipedrive",
    meta: pipedrivepO9aW72ETOMeta || {},
    alias: pipedrivepO9aW72ETOMeta?.alias || [],
    redirect: pipedrivepO9aW72ETOMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/crm/pipedrive.vue").then(m => m.default || m)
  },
  {
    name: salesforceJRyK9n0vgzMeta?.name ?? "integrations-crm-salesforce",
    path: salesforceJRyK9n0vgzMeta?.path ?? "/integrations/crm/salesforce",
    meta: salesforceJRyK9n0vgzMeta || {},
    alias: salesforceJRyK9n0vgzMeta?.alias || [],
    redirect: salesforceJRyK9n0vgzMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/crm/salesforce.vue").then(m => m.default || m)
  },
  {
    name: zohoshJ51jtiiYMeta?.name ?? "integrations-crm-zoho",
    path: zohoshJ51jtiiYMeta?.path ?? "/integrations/crm/zoho",
    meta: zohoshJ51jtiiYMeta || {},
    alias: zohoshJ51jtiiYMeta?.alias || [],
    redirect: zohoshJ51jtiiYMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/crm/zoho.vue").then(m => m.default || m)
  },
  {
    name: indexwfmqj0GVbMMeta?.name ?? "integrations",
    path: indexwfmqj0GVbMMeta?.path ?? "/integrations",
    meta: indexwfmqj0GVbMMeta || {},
    alias: indexwfmqj0GVbMMeta?.alias || [],
    redirect: indexwfmqj0GVbMMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: amplitudemR3QxO0Ge9Meta?.name ?? "integrations-product-analytics-amplitude",
    path: amplitudemR3QxO0Ge9Meta?.path ?? "/integrations/product-analytics/amplitude",
    meta: amplitudemR3QxO0Ge9Meta || {},
    alias: amplitudemR3QxO0Ge9Meta?.alias || [],
    redirect: amplitudemR3QxO0Ge9Meta?.redirect || undefined,
    component: () => import("/app/pages/integrations/product-analytics/amplitude.vue").then(m => m.default || m)
  },
  {
    name: mixpanelvMcFgoMCJGMeta?.name ?? "integrations-product-analytics-mixpanel",
    path: mixpanelvMcFgoMCJGMeta?.path ?? "/integrations/product-analytics/mixpanel",
    meta: mixpanelvMcFgoMCJGMeta || {},
    alias: mixpanelvMcFgoMCJGMeta?.alias || [],
    redirect: mixpanelvMcFgoMCJGMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/product-analytics/mixpanel.vue").then(m => m.default || m)
  },
  {
    name: chargebeeEWSq4NvvoMMeta?.name ?? "integrations-subscriptions-chargebee",
    path: chargebeeEWSq4NvvoMMeta?.path ?? "/integrations/subscriptions/chargebee",
    meta: chargebeeEWSq4NvvoMMeta || {},
    alias: chargebeeEWSq4NvvoMMeta?.alias || [],
    redirect: chargebeeEWSq4NvvoMMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/subscriptions/chargebee.vue").then(m => m.default || m)
  },
  {
    name: stripeM01Y0PtcXBMeta?.name ?? "integrations-subscriptions-stripe",
    path: stripeM01Y0PtcXBMeta?.path ?? "/integrations/subscriptions/stripe",
    meta: stripeM01Y0PtcXBMeta || {},
    alias: stripeM01Y0PtcXBMeta?.alias || [],
    redirect: stripeM01Y0PtcXBMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/subscriptions/stripe.vue").then(m => m.default || m)
  },
  {
    name: zoho_45books1XGUedZQDRMeta?.name ?? "integrations-subscriptions-zoho-books",
    path: zoho_45books1XGUedZQDRMeta?.path ?? "/integrations/subscriptions/zoho-books",
    meta: zoho_45books1XGUedZQDRMeta || {},
    alias: zoho_45books1XGUedZQDRMeta?.alias || [],
    redirect: zoho_45books1XGUedZQDRMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/subscriptions/zoho-books.vue").then(m => m.default || m)
  },
  {
    name: zoho_45subscriptionsBzYTTEDlvGMeta?.name ?? "integrations-subscriptions-zoho-subscriptions",
    path: zoho_45subscriptionsBzYTTEDlvGMeta?.path ?? "/integrations/subscriptions/zoho-subscriptions",
    meta: zoho_45subscriptionsBzYTTEDlvGMeta || {},
    alias: zoho_45subscriptionsBzYTTEDlvGMeta?.alias || [],
    redirect: zoho_45subscriptionsBzYTTEDlvGMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/subscriptions/zoho-subscriptions.vue").then(m => m.default || m)
  },
  {
    name: hubspot_45service_45hubaTSuA5aJ86Meta?.name ?? "integrations-ticketing-hubspot-service-hub",
    path: hubspot_45service_45hubaTSuA5aJ86Meta?.path ?? "/integrations/ticketing/hubspot-service-hub",
    meta: hubspot_45service_45hubaTSuA5aJ86Meta || {},
    alias: hubspot_45service_45hubaTSuA5aJ86Meta?.alias || [],
    redirect: hubspot_45service_45hubaTSuA5aJ86Meta?.redirect || undefined,
    component: () => import("/app/pages/integrations/ticketing/hubspot-service-hub.vue").then(m => m.default || m)
  },
  {
    name: intercomA6tXgIajDzMeta?.name ?? "integrations-ticketing-intercom",
    path: intercomA6tXgIajDzMeta?.path ?? "/integrations/ticketing/intercom",
    meta: intercomA6tXgIajDzMeta || {},
    alias: intercomA6tXgIajDzMeta?.alias || [],
    redirect: intercomA6tXgIajDzMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/ticketing/intercom.vue").then(m => m.default || m)
  },
  {
    name: jiraBN0dLMzSBXMeta?.name ?? "integrations-ticketing-jira",
    path: jiraBN0dLMzSBXMeta?.path ?? "/integrations/ticketing/jira",
    meta: jiraBN0dLMzSBXMeta || {},
    alias: jiraBN0dLMzSBXMeta?.alias || [],
    redirect: jiraBN0dLMzSBXMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/ticketing/jira.vue").then(m => m.default || m)
  },
  {
    name: zendesk76yx0rCdzwMeta?.name ?? "integrations-ticketing-zendesk",
    path: zendesk76yx0rCdzwMeta?.path ?? "/integrations/ticketing/zendesk",
    meta: zendesk76yx0rCdzwMeta || {},
    alias: zendesk76yx0rCdzwMeta?.alias || [],
    redirect: zendesk76yx0rCdzwMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/ticketing/zendesk.vue").then(m => m.default || m)
  },
  {
    name: batonll0yaI0XQVMeta?.name ?? "partnership-baton",
    path: batonll0yaI0XQVMeta?.path ?? "/partnership/baton",
    meta: batonll0yaI0XQVMeta || {},
    alias: batonll0yaI0XQVMeta?.alias || [],
    redirect: batonll0yaI0XQVMeta?.redirect || undefined,
    component: () => import("/app/pages/partnership/baton.vue").then(m => m.default || m)
  },
  {
    name: saasinsider2Ma9HmxEugMeta?.name ?? "partnership-saasinsider",
    path: saasinsider2Ma9HmxEugMeta?.path ?? "/partnership/saasinsider",
    meta: saasinsider2Ma9HmxEugMeta || {},
    alias: saasinsider2Ma9HmxEugMeta?.alias || [],
    redirect: saasinsider2Ma9HmxEugMeta?.redirect || undefined,
    component: () => import("/app/pages/partnership/saasinsider.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93otL1BKh9yuMeta?.name ?? "podcasts-slug",
    path: _91slug_93otL1BKh9yuMeta?.path ?? "/podcasts/:slug()",
    meta: _91slug_93otL1BKh9yuMeta || {},
    alias: _91slug_93otL1BKh9yuMeta?.alias || [],
    redirect: _91slug_93otL1BKh9yuMeta?.redirect || undefined,
    component: () => import("/app/pages/podcasts/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEHDN5ovAzVMeta?.name ?? "podcasts",
    path: indexEHDN5ovAzVMeta?.path ?? "/podcasts",
    meta: indexEHDN5ovAzVMeta || {},
    alias: indexEHDN5ovAzVMeta?.alias || [],
    redirect: indexEHDN5ovAzVMeta?.redirect || undefined,
    component: () => import("/app/pages/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: pricingpTmAIboEh0Meta?.name ?? "pricing",
    path: pricingpTmAIboEh0Meta?.path ?? "/pricing",
    meta: pricingpTmAIboEh0Meta || {},
    alias: pricingpTmAIboEh0Meta?.alias || [],
    redirect: pricingpTmAIboEh0Meta?.redirect || undefined,
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect || undefined,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: search_45resultsL4dkzeKwmzMeta?.name ?? "search-results",
    path: search_45resultsL4dkzeKwmzMeta?.path ?? "/search-results",
    meta: search_45resultsL4dkzeKwmzMeta || {},
    alias: search_45resultsL4dkzeKwmzMeta?.alias || [],
    redirect: search_45resultsL4dkzeKwmzMeta?.redirect || undefined,
    component: () => import("/app/pages/search-results.vue").then(m => m.default || m)
  },
  {
    name: sitemapbFBPe0edUzMeta?.name ?? "sitemap",
    path: sitemapbFBPe0edUzMeta?.path ?? "/sitemap",
    meta: sitemapbFBPe0edUzMeta || {},
    alias: sitemapbFBPe0edUzMeta?.alias || [],
    redirect: sitemapbFBPe0edUzMeta?.redirect || undefined,
    component: () => import("/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: customer_45churneSL15zKpSHMeta?.name ?? "solutions-customer-churn",
    path: customer_45churneSL15zKpSHMeta?.path ?? "/solutions/customer-churn",
    meta: customer_45churneSL15zKpSHMeta || {},
    alias: customer_45churneSL15zKpSHMeta?.alias || [],
    redirect: customer_45churneSL15zKpSHMeta?.redirect || undefined,
    component: () => import("/app/pages/solutions/customer-churn.vue").then(m => m.default || m)
  },
  {
    name: customer_45success_45headsEsztkf68EaMeta?.name ?? "solutions-customer-success-heads",
    path: customer_45success_45headsEsztkf68EaMeta?.path ?? "/solutions/customer-success-heads",
    meta: customer_45success_45headsEsztkf68EaMeta || {},
    alias: customer_45success_45headsEsztkf68EaMeta?.alias || [],
    redirect: customer_45success_45headsEsztkf68EaMeta?.redirect || undefined,
    component: () => import("/app/pages/solutions/customer-success-heads.vue").then(m => m.default || m)
  },
  {
    name: customer_45success_45managersDpMaI2iHwEMeta?.name ?? "solutions-customer-success-managers",
    path: customer_45success_45managersDpMaI2iHwEMeta?.path ?? "/solutions/customer-success-managers",
    meta: customer_45success_45managersDpMaI2iHwEMeta || {},
    alias: customer_45success_45managersDpMaI2iHwEMeta?.alias || [],
    redirect: customer_45success_45managersDpMaI2iHwEMeta?.redirect || undefined,
    component: () => import("/app/pages/solutions/customer-success-managers.vue").then(m => m.default || m)
  },
  {
    name: customer_45visibilityByo0VYvZgQMeta?.name ?? "solutions-customer-visibility",
    path: customer_45visibilityByo0VYvZgQMeta?.path ?? "/solutions/customer-visibility",
    meta: customer_45visibilityByo0VYvZgQMeta || {},
    alias: customer_45visibilityByo0VYvZgQMeta?.alias || [],
    redirect: customer_45visibilityByo0VYvZgQMeta?.redirect || undefined,
    component: () => import("/app/pages/solutions/customer-visibility.vue").then(m => m.default || m)
  },
  {
    name: founders_45and_45cxosmAro6VpWi0Meta?.name ?? "solutions-founders-and-cxos",
    path: founders_45and_45cxosmAro6VpWi0Meta?.path ?? "/solutions/founders-and-cxos",
    meta: founders_45and_45cxosmAro6VpWi0Meta || {},
    alias: founders_45and_45cxosmAro6VpWi0Meta?.alias || [],
    redirect: founders_45and_45cxosmAro6VpWi0Meta?.redirect || undefined,
    component: () => import("/app/pages/solutions/founders-and-cxos.vue").then(m => m.default || m)
  },
  {
    name: product_45adoptionz2g9wDBa8WMeta?.name ?? "solutions-product-adoption",
    path: product_45adoptionz2g9wDBa8WMeta?.path ?? "/solutions/product-adoption",
    meta: product_45adoptionz2g9wDBa8WMeta || {},
    alias: product_45adoptionz2g9wDBa8WMeta?.alias || [],
    redirect: product_45adoptionz2g9wDBa8WMeta?.redirect || undefined,
    component: () => import("/app/pages/solutions/product-adoption.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionszK6eZNsNEXMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionszK6eZNsNEXMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionszK6eZNsNEXMeta || {},
    alias: terms_45and_45conditionszK6eZNsNEXMeta?.alias || [],
    redirect: terms_45and_45conditionszK6eZNsNEXMeta?.redirect || undefined,
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: thank_45youwub3K0ZhI4Meta?.name ?? "thank-you",
    path: thank_45youwub3K0ZhI4Meta?.path ?? "/thank-you",
    meta: thank_45youwub3K0ZhI4Meta || {},
    alias: thank_45youwub3K0ZhI4Meta?.alias || [],
    redirect: thank_45youwub3K0ZhI4Meta?.redirect || undefined,
    component: () => import("/app/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: _91token_935yfqHd94b8Meta?.name ?? "verify-token",
    path: _91token_935yfqHd94b8Meta?.path ?? "/verify/:token()",
    meta: _91token_935yfqHd94b8Meta || {},
    alias: _91token_935yfqHd94b8Meta?.alias || [],
    redirect: _91token_935yfqHd94b8Meta?.redirect || undefined,
    component: () => import("/app/pages/verify/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MTErUPid3CMeta?.name ?? "webinars-slug",
    path: _91slug_93MTErUPid3CMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93MTErUPid3CMeta || {},
    alias: _91slug_93MTErUPid3CMeta?.alias || [],
    redirect: _91slug_93MTErUPid3CMeta?.redirect || undefined,
    component: () => import("/app/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexIbGDnNNySuMeta?.name ?? "webinars",
    path: indexIbGDnNNySuMeta?.path ?? "/webinars",
    meta: indexIbGDnNNySuMeta || {},
    alias: indexIbGDnNNySuMeta?.alias || [],
    redirect: indexIbGDnNNySuMeta?.redirect || undefined,
    component: () => import("/app/pages/webinars/index.vue").then(m => m.default || m)
  }
]